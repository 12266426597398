import React, { Component } from "react";
import styled from "styled-components";
import Chart from "react-google-charts";
import Wrapper from "../customdatepicker";
import devices from "./nodes.json";
import SensorCol from "./SensorCol.json";
import moment from "moment";
import "./style.css";
import 'font-awesome/css/font-awesome.min.css';
const axios = require('axios');


let Status=true;
const LoadingDiv = styled.div`
  position: fixed;
  top: 10%;
  right: 45%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const DateInput = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
`;
const Logo = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
`;
const SelectDeviceDiv = styled.div`
  background: white;
  position: absolute;
  top: 15px;
  right: 545px;
  left: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const SelectDevice = styled.select`
  margin-right: 15px;
  padding: 10px 15px 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
`;
const SelectDeviceOption = styled.option`
  width: 200px;
  padding: 10px 10px 10px 10px;
  font-size: 16px;
`;
const SelectSensorDiv = styled.div`
  background: white;
  position: absolute;
  top: 15px;
  right: 425px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const SelectSensor = styled.select`
  margin-right: 15px;
  padding: 10px 15px 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
`;
const SelectSensorOption = styled.option`
  width: 200px;
  padding: 10px 10px 10px 10px;
  font-size: 16px;
`;

const SelectSensorColDiv = styled.div`
  background: white;
  position: absolute;
  top: 15px;
  right: 250px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const SelectSensorCol = styled.select`
  margin-right: 15px;
  padding: 10px 15px 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
`;
const SelectSensorColOption = styled.option`
  width: 200px;
  padding: 10px 10px 10px 10px;
  font-size: 16px;
`;

const LogoIITD = styled.img`
position: absolute;
top: 65px;
left: 10px;
border-radius:25px;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
`

const LogoLabelIITD = styled.label`
padding:5px 10px 10px 10px;
position: absolute;
background:white;
top: 70px;
left: 70px;
font-weight:bold;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
border-radius:5px;
`

const LogoLabel = styled.label`
  padding: 5px 10px 10px 10px;
  position: absolute;
  background: white;
  top: 15px;
  left: 70px;
  font-weight: bold;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const ChartDiv = styled.div`
  position: fixed;
  margin-top: 130px;
  max-width: 100%;
`;

const Download = styled.button`
margin-left:70px;
font-weight:bold;
backgroud-color:white;
outline:none;
border:none;
padding: 5px 10px 5px 10px;
border-radius:5px;
`

// const BASE_URL = `http://localhost:5000/aerogram-testing-eb41a/us-central1/app/`;

const BASE_URL = `https://us-central1-aerogram-testing-eb41a.cloudfunctions.net/app/`;

class ChartComponent extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let currentDateTime = moment(
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes()
      )
    ); //.subtract(1,'days');
    let start = moment(currentDateTime).subtract(10, "minutes");
    let end = moment(currentDateTime);
    this.state = {
      timeInterval: 50000,
      data: [], //Nodes,
      start: start.format("YYYY-MM-DD HH:mm:SS"),
      end: end.format("YYYY-MM-DD HH:mm:SS"),
      deviceId: "ez-01",
      sensor: "pol",
      sensor_col: "PM2_5",

      loading: false,
      units: {
        PM2_5: "\u03BCg/m3",
        PM1_0: "\u03BCg/m3",
        PM10: "\u03BCg/m3",
        Humidity: "RH(%)",
        Temperature: "\u00b0C",
      },
      unit: "\u03BCg/m3",
      SensorColOptions: SensorCol[0].map((sensor) => {
        return sensor.map((sensorCol) => {
          return (
            <SelectSensorColOption style key={sensorCol} value={sensorCol}>
              {sensorCol}
            </SelectSensorColOption>
          );
        });
      }),
    };
  }
  handleDataFetching = async () => {
      let url = `${BASE_URL}charts?start_date=${this.state.start}&end_date=${this.state.end}&device_id=${this.state.deviceId}&sensor_col=${this.state.sensor_col}`
    //console.log(url);  
    this.setState({
      loading: true,
    });
    try {
      var self = this;      
      axios.get(`${url}`)
        .then(function (response) {
          // handle success

          let data = response.data;
          console.log(response.data);
            self.setState({
            loading: false,
          });

          self.setState({ data });
          return;

        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    } catch (e) { }
  };

  componentWillMount() {
    this.handleDataFetching();
  }

  handleDeviceChange = (e) => {
    this.setState(
      {
        deviceId: e.target.value,
      },
      () => {
        this.handleDataFetching();
      }
    );
  };

  handleSensorChange = (e) => {
    let selectedSensor = e.target.value;
    let sensorIndex = 0;
    let selectedSensorCol = "PM2_5";
    let units = this.state.units;
    selectedSensor === "pol"
      ? (sensorIndex = 0)
      : selectedSensor === "bme"
        ? (sensorIndex = 1)

        : (sensorIndex = 0);

    selectedSensor === "pol"
      ? (selectedSensorCol = "PM2_5")
      : selectedSensor === "bme"
        ? (selectedSensorCol = "Temperature")

        : (selectedSensorCol = "PM2_5");
    this.setState(
      {
        SensorColOptions: SensorCol[sensorIndex].map((sensor) => {
          return sensor.map((sensorCol) => {
            return (
              <SelectSensorColOption style key={sensorCol} value={sensorCol}>
                {sensorCol}
              </SelectSensorColOption>
            );
          });
        }),
        sensor: e.target.value,
        sensor_col: selectedSensorCol,
        unit: units[selectedSensorCol],
      },
      () => {
        this.handleDataFetching();
      }
    );
  };

  handleSensorColChange = (e) => {
    let units = this.state.units;
    this.setState(
      {
        sensor_col: e.target.value,
        unit: units[e.target.value],
      },
      () => {
        this.handleDataFetching();
      }
    );
  };

  handleStartAndEndSubmit = async (start, end) => {
    try {
      let startDateTime = moment(start).format("YYYY-MM-DD HH:mm:ss");
      let endDateTime = moment(end).format("YYYY-MM-DD HH:mm:ss");
      this.setState(
        {
          start: startDateTime,
          end: endDateTime,
        },
        () => {
          this.handleDataFetching();
        }
      );
    } catch (e) { }
  };

  options = devices.map((device) => {
    return (
      <SelectDeviceOption style key={device.deviceId} value={device.deviceId}>
        Device: {device.deviceId}
      </SelectDeviceOption>
    );
  });

  handleDownload = async () => {
     this.setState({
      loading: true,
    });
    try {
      var self = this;      
      fetch(`${BASE_URL}download?device_id=${this.state.deviceId}&start_date=${this.state.start}&end_date=${this.state.end}`).then(
        (response) => {
          return response.json()
        }).then((resdata) => {
          // console.log("resdata"+resdata)
          self.setState({
            loading: false,
          });
          if (resdata.length === 0) {
            alert("No data found for current input!")
          }
          else {
            let file = ["timestamp,date,time,pm1.0,pm2.5,pm10,temperature,humidity\n"];
            resdata.forEach((val) => {
              file.push(`${val.timestamp},${new Date(val.uplink_message.ts._seconds * 1000).toLocaleString()},${val.uplink_message.decoded_payload.PM1_0},${val.uplink_message.decoded_payload.PM2_5},${val.uplink_message.decoded_payload.PM1_0},${val.uplink_message.decoded_payload.Temperature},${val.uplink_message.decoded_payload.Humidity}\n`);
            })

            let fileToExport = "";

            file.forEach((val) => {
              fileToExport = fileToExport + val;
            })

            //console.log("fileToExport",fileToExport);

            var dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(fileToExport);
            var dlAnchorElem = document.getElementById('downloadAnchorElem');
            dlAnchorElem.setAttribute("href", dataStr);
            dlAnchorElem.setAttribute("download", `${this.state.deviceId}.csv`);
            dlAnchorElem.click();
          }
        })

    } catch (e) { }

  }
  render() {
    Status=true;
    let updatedData = this.state.data;
        let chartData = updatedData.map((val) => {
        var a= moment(val.time * 1000).format("MMM DD HH:mm:SS");
        var b=val.data;       
        if(b != null) {
          Status=false;
           }
         return[a,b];        
    });
    //console.log(chartData)
    return (  
          <>
        <Logo src="/logo_aero.png" height="45" width="45" />
        <LogoIITD src="/iitd_logo.png" height="45" width="45" />
        {/*<LogoDIMTS src="/dimts_logo.png" height="45" width="45" />*/}
        <LogoLabel>Aerogram</LogoLabel>
        <LogoLabelIITD>IIT, Delhi</LogoLabelIITD>
        {/*<LogoLabelDIMTS>DIMTS</LogoLabelDIMTS>*/}
        <DateInput id="dateRangeSelectorDiv">
          <a href="#" style={{ color: "black" }}>
            <Wrapper handleStartAndEndSubmit={this.handleStartAndEndSubmit} />
          </a>
        </DateInput>

        <SelectDeviceDiv id="deviceSelectorDiv">
          <SelectDevice onChange={this.handleDeviceChange}>
            {this.options}
          </SelectDevice>
        </SelectDeviceDiv>
        <SelectSensorDiv id="sensorSelectorDiv">
          <SelectSensor onChange={this.handleSensorChange}>
            <SelectSensorOption value="pol">POL</SelectSensorOption>
            <SelectSensorOption value="bme">BME</SelectSensorOption>

          </SelectSensor>
        </SelectSensorDiv>
        <SelectSensorColDiv id="sensorColSelectorDiv">
          <SelectSensorCol onChange={this.handleSensorColChange}>
            {this.state.SensorColOptions}
          </SelectSensorCol>
        </SelectSensorColDiv>
        <ChartDiv id="chartDiv">
          {this.state.loading ? (
            <LoadingDiv>
              <div className="loader"></div>
            </LoadingDiv>
          ) : ((chartData.length === 0) || (chartData.length >0 && Status==true)) ? (
            <Chart
              width={"100vw"}
              height={"75vh"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                ["no data", "no data"],
                [0, 0],
              ]}
              options={{
                hAxis: {
                  title: "Time",
                },
                vAxis: {
                  title: "no data",
                },
              }}
              rootProps={{ "data-testid": "1" }}
            />
          ) : (<>
            <Download id="download" style={{ color: "#3489eb" }} onClick={this.handleDownload}><i class="fa fa-download" aria-hidden="true"></i><br />Download</Download>
            <a id="downloadAnchorElem" style={{ display: "none" }}></a>
            <Chart
              width={"100vw"}
              height={"75vh"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                [{ type: "string", label: "Time" }, this.state.sensor_col],
                ...chartData,
              ]}
              options={{
                hAxis: {
                  title: "TIME",
                },
                vAxis: {
                  title: this.state.sensor_col + " (" + this.state.unit + ")",
                },
              }}
              rootProps={{ "data-testid": "1" }}
            /></>
          )}
        </ChartDiv>
      </>
    );
  }
}
export default ChartComponent;
